





























































import { getTypes } from '@/services/constants';
import { mapGetters } from 'vuex';
import rService from '../services/RequestService/RequestServiceSetup';


export default {
  name: 'MyEntries',
  props: {},
  data() {
    return {
      entryPage: false,
      grouped: false,
      entry: '',
      pagination: {
        pageSize: 10,
        page: 1,
        sort: { date: -1 }
      },
      entries: 0,
      page: 0,
      loading: false,
      values: [],
      totalPages: 0,
      itemsPerPage: 15,
      previousSearch: {}
    };
  },
  components: {
    search: () => import('@/components/CheckAccount/Search.vue')
  },
  watch: {
    entry(newValue) {
      this.searchEntries(this.grouped);
    },
    pagination: {
      handler(newVal, oldVal) {
        if (this.entries > 0) this.myEntries(newVal.page, this.grouped);
      },
      deep: true
    },
    'userInfo.unique_id'() {
      this.myEntries(this.pagination.page, this.grouped);
    }
  },
  methods: {
    getQueryParams() {
      return {
        page: this.pagination.page,
        grouped: this.grouped
      };
    },
    searchEntries(grouped = false) {
      /* Define grouped */
      let page = 1;
      if (this.entryPage) {
        this.entryPage = false;
        page = this.pagination.page;
      }
      this.grouped = grouped == true;
      this.myEntries(page, grouped);
    },
    changePage(newPage) {},
    processDescription() {},
    /* async getTotalPages(grouped: boolean = false) {
      if (this.userInfo && this.userInfo.platform) {
        this.loading = true;
        const entryEncode = encodeURIComponent(this.entry);
        const entries = await rService.get(`my_entries/${this.userInfo.platform}?count=true&entry=${entryEncode}&grouped=${grouped}`, true);
        if (entries && !entries.error) {
          this.totalPages = Math.ceil(entries / this.itemsPerPage);
          this.entries = entries;
        }
        this.loading = false;
      }
    }, */
    async myEntries(page, grouped = false) {
      // this.values = [];
      this.pagination.page = page;
      let sort = null;
      if (this.pagination.sortBy && this.pagination.sortBy.length) {
        const key = this.pagination.sortBy[0];
        const desc = this.pagination.sortDesc[0];
        sort = {
          [key]: desc ? -1 : 1
        };
      }
      this.itemsPerPage = this.pagination.itemsPerPage;
      if (this.userInfo && this.userInfo.platform) {
        this.loading = true;
        const newSearch = {
          grouped: this.grouped,
          pageSize: this.itemsPerPage ? this.itemsPerPage : 10,
          page: this.pagination.page,
          entry: this.entry,
          sort: sort || { date: -1 }
        };
        if (JSON.stringify(newSearch) === JSON.stringify(this.previousSearch)) {
          this.loading = false;
          return
        } else {
          this.previousSearch = { ...newSearch };
        }
        const { results, pagination } = await rService.post(`my_entries_new/${this.userInfo.platform}`, newSearch, true);
        this.totalPages = pagination.pageCount;
        this.entries = pagination.rowCount;
        if (results && !results.error) {
          this.values = results;
          this.loading = false;
          this.$router.push({ path: `/myEntries/${this.entry}`, query: this.getQueryParams() }).catch();
        }
        this.loading = false;
      }
    },
    timeAgo(date: string) {
      const utcMoment = this.$moment.utc();
      const dateConverted = this.$moment(date);
      const sTime = dateConverted.from(utcMoment);
      return sTime;
    },
    formatDate(date) {
      return this.$moment(date).format('YYYY-MM-DD HH:mm:ss');
    },
    translate(item) {
      return this.$vuetify.lang.t('$vuetify.Home.' + item);
    },
    getType(type) {
      const el = this.getTypes.find(function (el) {
        return el.value == type;
      })
      if (el) return el.name;
    }
  },
  computed: {
    getTypes() {
      return getTypes(this.translate);
    },
    ...mapGetters(['userInfo']),
    headers() {
      return [
        { text: 'ID', value: 'id', width: '90px' },
        { text: 'Ip Address', value: 'ip', width: '100px' },
        { text: 'User Agent', value: 'user_agent', width: '300px' },
        { text: 'Type', value: 'type', width: '300px' },
        { text: 'Breaches Found', value: 'breachFound', width: '100px' },
        { text: 'Password Found', value: 'passFound', width: '100px' },
        { text: 'Entry', value: 'entry', width: '100px' },
        { text: 'Found', value: 'found', width: '90px' },
        { text: 'Found with Pass', value: 'foundWithPass', width: '90px' },
        { text: 'Date', value: 'date', width: '200px' }
      ];
    }
  },
  mounted() {
    const params = this.$router.currentRoute.params;
    if (params && params.entry) {
      this.entry = params.entry;
      this.entryPage = true;
    }
    const queryParams = this.$router.currentRoute.query;
    if (queryParams) {
      let { grouped, page } = queryParams;
      if (grouped) {
        this.grouped = grouped == true;
      }
      if (page) {
        page = parseInt(page);
        if (isNaN(page)) page = 1;
        this.pagination.page = page;
      }
    }
    this.myEntries(this.pagination.page, this.grouped);
  }
};
