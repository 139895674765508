var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.userInfo.email)?_c('div',[(_vm.entries)?_c('v-data-table',{staticClass:"primary elevation-1 data-entries",attrs:{"options":_vm.pagination,"sort-by":'date',"sort-desc":true,"dark":true,"headers":_vm.headers,"items":_vm.values,"loading":_vm.loading,"server-items-length":_vm.entries,"items-per-page":_vm.itemsPerPage,"item-key":"name"},on:{"update:options":function($event){_vm.pagination=$event}},scopedSlots:_vm._u([{key:"top",fn:function(ref){
var pagination = ref.pagination;
var options = ref.options;
var updateOptions = ref.updateOptions;
return [_c('search',{ref:"searchForm",attrs:{"groupedEntry":_vm.grouped,"entry":_vm.entry},on:{"groupedChange":_vm.searchEntries,"update:entry":function($event){_vm.entry=$event}}}),_c('v-data-footer',{staticClass:"top-pagination navBarBlue",attrs:{"pagination":pagination,"options":options,"items-per-page-text":"$vuetify.dataTable.itemsPerPageText"},on:{"update:options":updateOptions}})]}},{key:"item.type",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.getType(item.type)))])]}},{key:"item.entry",fn:function(ref){
var item = ref.item;
return [_c('div',[(item.entry && (item.breachFound || item.passFound))?_c('router-link',{attrs:{"to":{ name: 'home', params: { id: item.id, entrySearch: _vm.entry } }}},[_c('v-chip',{attrs:{"color":"blue","text-color":"white"}},[_vm._v(_vm._s(item.entry))])],1):(item.entry)?_c('div',[_c('v-chip',{attrs:{"disabled":true,"color":"blue","text-color":"white"}},[_vm._v(_vm._s(item.entry))])],1):_c('span',[_vm._v("Not saved")])],1)]}},{key:"item.breachFound",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.breachFound ? 'Yes' : 'No'))])]}},{key:"item.passFound",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.passFound ? 'Yes' : 'No'))])]}},{key:"item.date",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-block"},[_c('span',{staticClass:"d-block"},[_vm._v(_vm._s(_vm.formatDate(item.date)))]),_c('span',{staticClass:"d-block"},[_vm._v(_vm._s(_vm.timeAgo(item.date)))])])]}}],null,false,1639123176)},[_c('template',{slot:"body.prepend"},[_c('v-overlay',{attrs:{"absolute":true,"opacity":0.8,"value":_vm.loading,"z-index":99999}})],1)],2):_vm._e(),_c('v-row',{attrs:{"justify":"center"}},[_c('v-col',{attrs:{"cols":"8"}},[_c('v-container',{staticClass:"max-width"},[_c('v-pagination',{staticClass:"my-4",attrs:{"disabled":_vm.loading,"length":_vm.totalPages},model:{value:(_vm.pagination.page),callback:function ($$v) {_vm.$set(_vm.pagination, "page", $$v)},expression:"pagination.page"}})],1)],1)],1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }